import React, {useState, useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import {collection, query, orderBy, getDocs} from 'firebase/firestore'
import {db} from '../../firebase'
import {useAuth} from '../../contexts/AuthContext'

import Header from '../../components/Header'
import Card from '../../components/Card'
import EventCard from '../../components/EventCard'
import LoadingSpinner from '../../components/LoadingSpinner'

export default function Events() {
  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const {user} = useAuth()

  useEffect(() => {
    const colRef = collection(db, 'clubs', user.clubId, 'events')
    const q = query(colRef, orderBy('createdAt', 'desc'))
    getDocs(q)
      .then((docSnapshots) => {
        setEvents(
          docSnapshots.docs.map((docSnapshot) => ({
            id: docSnapshot.id,
            ...docSnapshot.data()
          }))
        )
        setIsLoading(false)
      })
      .catch((err) => console.log(err.message))
  }, [])

  const eventsGrid =
    events.length === 0 ? (
      <Card>
        <h3 className='events__heading'>No Events Yet...</h3>
        <p>Time to create one.</p>
      </Card>
    ) : (
      events.map((event) => <EventCard key={event.id} event={event} />)
    )

  return (
    <article className='events'>
      <Header title='Events' />
      <NavLink to='/admin/events/create' className='events__create'>
        Create Event
      </NavLink>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className='events__grid'>{eventsGrid}</div>
      )}
    </article>
  )
}
