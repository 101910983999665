import React from 'react'
import {useLocation} from 'react-router-dom'

import {ReactComponent as GavelLogo} from '../../icons/gavel-logo.svg'

import AuthLink from '../../components/AuthLink'
import NumberedText from '../../components/NumberedText'

export default function LandingPage() {
  const {pathname} = useLocation()

  return (
    <div className='landing-page'>
      <GavelLogo className='landing-page__logo' alt='Gavel Logo - Color' />
      <h3 className='landing-page__tagline'>An attendance tool for clubs.</h3>
      <div className='landing-page__buttons'>
        <AuthLink
          className='auth-link--darkbrown auth-link--landing-page'
          to='/login'
          label='Login'
        />
        <AuthLink
          className='auth-link--landing-page'
          to='/create-account'
          state={{from: pathname}}
          label='Create Account'
        />
      </div>
      <img
        className='landing-page__events-screenshot'
        src='/events-screenshot.png'
        alt='Screenshot of Events Page'
      />
      <h1 className='landing-page__heading'>Made for Admins and Members</h1>
      <div className='landing-page__steps'>
        <div className='landing-page__admin'>
          <h2 className='landing-page__subtitle'>Admins</h2>
          <NumberedText number={1} text='Create event' />
          <div className='landing-page__graphic'>
            <div className='landing-page__create'>Create Event</div>
          </div>
          <NumberedText number={2} text='Share attendance code' />
          <div className='landing-page__graphic'>
            <div className='landing-page__invite'>
              <h2 className='landing-page__inviteTitle'>Member Invite</h2>
              <div className='landing-page__inviteBox'>
                <p className='landing-page__inviteText'>
                  Login at: <strong>gavelapp.in/login</strong>
                </p>
                <p className='landing-page__inviteText'>
                  Submit Code: <strong>nlg06xzl</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='landing-page__member'>
          <h2 className='landing-page__subtitle'>Members</h2>
          <NumberedText number={1} text='Login or create account' />
          <div className='landing-page__graphic'>
            <div className='landing-page__auth'>
              <div className='landing-page__authLink'>Login</div>
              <p className='landing-page__or'>or</p>
              <div className='landing-page__authLink landing-page__authLink--createAccount'>
                Create Account
              </div>
            </div>
          </div>
          <NumberedText number={2} text='Submit attendance code' />
          <div className='landing-page__graphic'>
            <div className='landing-page__checkin'>
              <h2 className='landing-page__checkinTitle'>Enter Code</h2>
              <div className='landing-page__input'></div>
              <div className='landing-page__authLink'>Submit</div>
            </div>
          </div>
        </div>
      </div>
      <div className='landing-page__footer'>
        <p>
          Email:{' '}
          <a
            className='landing-page__link'
            href='mailto:gavelhelp@gmail.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            gavelhelp@gmail.com
          </a>
        </p>
        <div className='landing-page__legal'>
          <a
            className='landing-page__link'
            href='https://www.gavelapp.in/terms-of-service'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Service
          </a>
          <a
            className='landing-page__link'
            href='https://www.gavelapp.in/privacy-policy'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  )
}
