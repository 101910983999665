import React from 'react'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../contexts/AuthContext'
import useDropdownState from '../hooks/useDropdownState'

import {ReactComponent as BackIcon} from '../icons/back.svg'

import ProfileDropdownMenu from './ProfileDropdownMenu'

export default function Header({title, backButton}) {
  const navigate = useNavigate()
  const {user} = useAuth()
  const [showDropdown, dropdownRef, toggleDropdown] = useDropdownState()

  return (
    <header className='header'>
      <div className='header__div'>
        {backButton && (
          <BackIcon
            className='header__back'
            alt='Back Icon - White'
            onClick={() => navigate(-1)}
          />
        )}
        <h1 className='header__title'>{title}</h1>
      </div>
      <div className='header__dropdown' ref={dropdownRef}>
        <div className='header__profile' onClick={toggleDropdown}>
          {user.displayName[0]}
        </div>
        {showDropdown && <ProfileDropdownMenu />}
      </div>
    </header>
  )
}

Header.defaultProps = {
  backButton: false
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  backButton: PropTypes.bool
}
