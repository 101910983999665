import React from 'react'

import Grid from '../components/Grid'
import Main from '../components/Main'
import NavBar from '../components/NavBar'
import NavButton from '../components/NavButton'

export default function MemberLayout({children}) {
  return (
    <Grid>
      <NavBar>
        <NavButton to='/member/clubs' label='Clubs' />
        <NavButton to='/member/account' label='Account' />
      </NavBar>
      <Main>{children}</Main>
    </Grid>
  )
}
