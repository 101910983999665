import React from 'react'
import PropTypes from 'prop-types'

export default function NumberedText({number, text}) {
  return (
    <div className='numbered-text'>
      <h2 className='numbered-text__number'>{number}.</h2>
      <p className='numbered-text__text'>{text}</p>
    </div>
  )
}

NumberedText.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
}
