import React from 'react'
import PropTypes from 'prop-types'

export default function AuthButton({type, label, onClick, disabled}) {
  return (
    <button
      className={'auth-button' + (disabled ? ' auth-button--disabled' : '')}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  )
}

AuthButton.defaultProps = {
  type: 'submit'
}

AuthButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func
}
