import React, {useEffect, useState} from 'react'
import {doc, getDoc} from 'firebase/firestore'
import {db} from '../../firebase'
import {useAuth} from '../../contexts/AuthContext'
import {NavLink} from 'react-router-dom'

import Header from '../../components/Header'
import Card from '../../components/Card'
import ClubCard from '../../components/ClubCard'
import LoadingSpinner from '../../components/LoadingSpinner'

export default function Clubs() {
  const [clubs, setClubs] = useState([])
  const [loading, setLoading] = useState(true)

  const {user} = useAuth()

  useEffect(() => {
    const docRef = doc(db, 'members', user.uid)
    getDoc(docRef)
      .then((docSnapshot) => {
        const clubObjs = docSnapshot.data().clubs
        const clubKeys = clubObjs ? Object.keys(clubObjs) : []
        const clubArr = clubKeys.map((key) => ({
          id: key,
          ...clubObjs[key]
        }))
        if (clubArr.length)
          clubArr.sort((a, b) => a.joinedAt.seconds - b.joinedAt.seconds)
        setClubs(clubArr)
        setLoading(false)
      })
      .catch((err) => console.log(err.message))
  }, [])

  const clubsGrid =
    clubs.length === 0 ? (
      <Card>
        <h3 className='clubs__heading'>No Clubs Yet...</h3>
        <p>
          You will automatically be registered in a club after you submit a code
          for your first club event.
        </p>
      </Card>
    ) : (
      clubs.map((club) => <ClubCard key={club.id} club={club} />)
    )

  return (
    <article className='clubs'>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Header title='Clubs' />
          <NavLink to='/member/checkin' className='clubs__checkIn'>
            Submit Code
          </NavLink>
          <div className='clubs__grid'>{clubsGrid}</div>
        </>
      )}
    </article>
  )
}
