import React from 'react'
import PropTypes from 'prop-types'
import gradYearToGrade from '../util/gradYearToGrade'

import SelectSearch from 'react-select-search'
import {ReactComponent as AddIcon} from '../icons/add.svg'

import SaveLoadingSpinner from './SaveLoadingSpinner'
import SortDropdown from './SortDropdown'
import IconButton from './IconButton'
import SaveCloseButtons from './SaveCloseButtons'

export default function Table({
  className,
  title,
  head,
  dataLabels,
  data,
  noDataMessage,
  sortOptions,
  sortBy,
  handleSortChange,
  addData,
  addOptions,
  addLoading,
  handleAdd,
  handleAddChange,
  handleAddClose,
  handleAddSave,
  selectSearchRef,
  keyboardState,
  handleInputFocusChange
}) {
  const tableHeader = head.map((text, index) => <th key={index}>{text}</th>)

  const selectSearchBase = 'table__addSelectSearch'
  const selectSearchClassNames = addData?.value
    ? {
        container: `${selectSearchBase}Container`,
        input: `${selectSearchBase}Input ${selectSearchBase}Input--unsaved`,
        select: `${selectSearchBase}Select ${selectSearchBase}Select--unsaved`,
        options: `${selectSearchBase}Options`,
        option: `${selectSearchBase}Option ${selectSearchBase}Option--unsaved`,
        row: `${selectSearchBase}Row`,
        'is-highlighted': `${selectSearchBase}Option--isHighlighted ${selectSearchBase}Option--unsavedIsHighlighted`,
        'is-selected': `${selectSearchBase}Option--isSelected ${selectSearchBase}Option--unsavedIsSelected`,
        'has-focus': `${selectSearchBase}--hasFocus`
      }
    : {
        container: `${selectSearchBase}Container`,
        input: `${selectSearchBase}Input`,
        select: `${selectSearchBase}Select`,
        options: `${selectSearchBase}Options`,
        option: `${selectSearchBase}Option`,
        row: `${selectSearchBase}Row`,
        'is-highlighted': `${selectSearchBase}Option--isHighlighted`,
        'is-selected': `${selectSearchBase}Option--isSelected`,
        'has-focus': `${selectSearchBase}--hasFocus`
      }

  function tableData() {
    if (data.length === 0)
      return (
        <tr className='table__row'>
          <td colSpan={3}>{noDataMessage}</td>
        </tr>
      )
    return data.map((datum, index) => {
      const data = dataLabels.map(dataLabel => (
        <td key={dataLabel}>{datum[dataLabel]}</td>
      ))
      return (
        <tr key={index} className='table__row'>
          {data}
        </tr>
      )
    })
  }

  return (
    <div className={'table' + (className ? ` ${className}` : '')}>
      {(title || sortOptions) && (
        <div className='table__flex'>
          {title && <h2 className='table__title'>{title}</h2>}
          {sortOptions && (
            <SortDropdown
              sortOptions={sortOptions}
              sortBy={sortBy}
              handleChange={handleSortChange}
            />
          )}
        </div>
      )}
      <table className='table__content'>
        <thead>
          <tr className='table__header'>{tableHeader}</tr>
        </thead>
        <tbody>
          {addData &&
            (addData.timestamp ? (
              <tr className='table__row'>
                <td>{addData.timestamp}</td>
                <td>
                  <SelectSearch
                    className={selectSearchClassNames}
                    ref={selectSearchRef}
                    options={addOptions ? addOptions : []}
                    value={addData.value}
                    placeholder={
                      addOptions
                        ? addOptions.length
                          ? 'Search Member...'
                          : 'All Members Checked-in'
                        : 'Loading Members...'
                    }
                    emptyMessage={
                      addOptions && addOptions.length ? (
                        <p className='table__addSelectSearchEmptyMessage'>
                          Invalid Name
                        </p>
                      ) : null
                    }
                    search={true}
                    disabled={!addOptions || !addOptions.length || addLoading}
                    onChange={handleAddChange}
                    onFocus={() => handleInputFocusChange(true)}
                    onBlur={() => handleInputFocusChange(false)}
                  />
                </td>
                <td className='table__data--withButtons'>
                  <p>
                    {addData.gradYear ? gradYearToGrade(addData.gradYear) : '—'}
                  </p>
                  {addLoading ? (
                    <SaveLoadingSpinner />
                  ) : (
                    <SaveCloseButtons
                      tooltip={true}
                      keyboardState={keyboardState}
                      showSave={Boolean(addData.value)}
                      handleSave={handleAddSave}
                      handleClose={handleAddClose}
                    />
                  )}
                </td>
              </tr>
            ) : (
              <tr className='table__row'>
                <td className='table__data--add' colSpan={3}>
                  <IconButton
                    className='icon-button--white'
                    onClick={handleAdd}
                  >
                    <AddIcon
                      className='table__icon'
                      alt='Add Icon - Light Brown'
                    />
                    <p className='table__addLabel'>Add</p>
                  </IconButton>
                </td>
              </tr>
            ))}
          {tableData()}
        </tbody>
      </table>
    </div>
  )
}

Table.defaultProps = {
  noDataMessage: 'No Data'
}

Table.propTypes = {
  title: PropTypes.string,
  head: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  noDataMessage: PropTypes.string,
  sortOptions: PropTypes.arrayOf(PropTypes.string),
  sortBy: PropTypes.string,
  handleSortChange: PropTypes.func,
  addData: PropTypes.object,
  addOptions: PropTypes.arrayOf(PropTypes.object),
  addLoading: PropTypes.bool,
  handleAdd: PropTypes.func,
  handleAddChange: PropTypes.func,
  handleAddClose: PropTypes.func,
  handleAddSave: PropTypes.func,
  selectSearchRef: PropTypes.object,
  keyboardState: PropTypes.string,
  handleInputFocusChange: PropTypes.func
}
