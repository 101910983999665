import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../contexts/AuthContext'

import NavButton from './NavButton'

export default function ProfileDropdownMenu() {
  const {user, logout} = useAuth()
  const navigate = useNavigate()

  function handleClick() {
    logout()
      .then(() => navigate('/login'))
      .catch(err => console.log(err.message))
  }

  return (
    <div className='profile-dropdown-menu'>
      <h3 className='profile-dropdown-menu__name'>{user.displayName}</h3>
      <div className='profile-dropdown-menu__buttons'>
        <NavButton
          className='nav-button--short'
          to={`/${user.accountType}/account`}
          label='Account'
        />
        <button className='profile-dropdown-menu__button' onClick={handleClick}>
          Logout
        </button>
      </div>
    </div>
  )
}
