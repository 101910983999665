import React from 'react'
import {useAuth} from '../../contexts/AuthContext'

import Header from '../../components/Header'

export default function Account() {
  const {user} = useAuth()

  return (
    <article className='account'>
      <Header title='Account' />
      <div className='account__info'>
        <p className='account__label'>Account Type</p>
        <p className='account__data'>
          {user.accountType === 'member' ? 'Member' : 'Admin'}
        </p>
        <p className='account__label'>Name</p>
        <p className='account__data'>{user.displayName}</p>
        <p className='account__label'>Email Address</p>
        <p className='account__data'>{user.email}</p>
        {/* <button className="account__button">Reset Password</button> */}
      </div>
    </article>
  )
}
