import React from 'react'
import PropTypes from 'prop-types'

export default function StatusMessage({children, type}) {
  return (
    <div
      className={'status-message' + (type ? ` status-message--${type}` : '')}
    >
      {children}
    </div>
  )
}

StatusMessage.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string
}
