import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {collection, addDoc, serverTimestamp} from 'firebase/firestore'
import {db} from '../../firebase'
import {useAuth} from '../../contexts/AuthContext'
import generateCode from '../../util/generateCode'

import Header from '../../components/Header'
import EventForm from '../../components/EventForm'
import LoadingSpinner from '../../components/LoadingSpinner'

export default function EventCreate() {
  const navigate = useNavigate()
  const {user} = useAuth()

  const [formData, setFormData] = useState({
    status: 'Active',
    name: '',
    date: '',
    points: ''
  })
  const [isLoading, setIsLoading] = useState(false)

  function handleFormChange(e) {
    const {name, value, type} = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value !== '' && type === 'number' ? parseInt(value) : value
    }))
  }

  function handleSave(e) {
    e.preventDefault()
    setIsLoading(true)
    const colRef = collection(db, 'clubs', user.clubId, 'events')
    addDoc(colRef, {
      ...formData,
      attendees: {},
      clubId: user.clubId,
      code: generateCode(8),
      createdAt: serverTimestamp()
    })
      .then(({id}) => navigate(`/admin/events/${id}/details`))
      .catch((err) => console.log(err.message))
  }

  function handleCancel(e) {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <div className='event-create'>
      <Header title='Create Event' />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <EventForm
          formData={formData}
          handleFormChange={handleFormChange}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      )}
    </div>
  )
}
