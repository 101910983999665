import React, {useState, useEffect} from 'react'
import {
  doc,
  collection,
  query,
  where,
  getDoc,
  getDocs
} from 'firebase/firestore'
import {db} from '../../firebase'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../contexts/AuthContext'

import Header from '../../components/Header'
import LoadingSpinner from '../../components/LoadingSpinner'
import LabeledText from '../../components/LabeledText'
import Table from '../../components/Table'

export default function ClubDetails() {
  const [club, setClub] = useState()
  const [sortBy, setSortBy] = useState('Date: Newest-Oldest')
  const [loading, setLoading] = useState(true)

  const {clubId} = useParams()
  const {user} = useAuth()

  useEffect(() => {
    // Get club details from members collection
    const memberDocRef = doc(db, 'members', user.uid)
    getDoc(memberDocRef)
      .then(docSnapshot => {
        const clubObj = docSnapshot.data().clubs[clubId]
        setClub({id: clubId, ...clubObj})
        // Get attendance history from clubs/events subcollection
        const colRef = collection(db, 'clubs', clubId, 'events')
        const q = query(colRef, where(`attendees.${user.uid}`, '!=', null))
        return getDocs(q)
      })
      .then(docSnapshots => {
        const eventsArr = docSnapshots.docs
          .map(docSnapshot => {
            const {date, name, points} = docSnapshot.data()
            return {
              id: docSnapshot.id,
              date: date,
              name: name,
              points: points
            }
          })
          .sort(
            (a, b) =>
              new Date(b.date).getTime() - new Date(a.date).getTime() ||
              a.name.localeCompare(b.name)
          )
        setClub(prevClub => ({
          ...prevClub,
          attendance: eventsArr
        }))
        setLoading(false)
      })
      .catch(err => console.log(err.message))
  }, [])

  const sortOptions = [
    'Date: Newest-Oldest',
    'Date: Oldest-Newest',
    'Event Name: A-Z',
    'Event Name: Z-A',
    'Points: Increasing',
    'Points: Decreasing'
  ]

  function sortClubAttendance(attendance, sortBy) {
    return attendance.toSorted((a, b) => {
      const aDate = new Date(a.date).getTime()
      const bDate = new Date(b.date).getTime()

      switch (sortBy) {
        case 'Date: Oldest-Newest':
          return aDate - bDate || a.name.localeCompare(b.name)
        case 'Event Name: A-Z':
          return a.name.localeCompare(b.name) || bDate - aDate
        case 'Event Name: Z-A':
          return b.name.localeCompare(a.name) || bDate - aDate
        case 'Points: Increasing':
          return a.points - b.points || bDate - aDate
        case 'Points: Decreasing':
          return b.points - a.points || bDate - aDate
        default:
          return bDate - aDate || a.name.localeCompare(b.name)
      }
    })
  }

  function handleSortChange(e) {
    const newSortBy = e.target.value
    setSortBy(newSortBy)
    setClub(prevClub => {
      const newClubAttendance = sortClubAttendance(
        prevClub.attendance,
        newSortBy
      )
      return {
        ...prevClub,
        attendance: newClubAttendance
      }
    })
  }

  function tableData() {
    return club.attendance.map(attendanceEntry => ({
      ...attendanceEntry,
      date: new Date(attendanceEntry.date).toLocaleDateString('en-US', {
        timeZone: 'UTC'
      })
    }))
  }

  return (
    <article className='club-details'>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Header title={`${club.name} Details`} />
          <div className='club-details__grid'>
            <LabeledText
              className='labeled-text--d'
              text={club.carryover + club.new}
              label='Total Points'
            />
            <LabeledText
              className='labeled-text--d'
              text={club.carryover}
              label='Carryover Points'
            />
          </div>
          <Table
            title='Attendance History'
            head={['Date', 'Event Name', 'Points']}
            dataLabels={['date', 'name', 'points']}
            data={tableData()}
            noDataMessage='Attendance history will show up here...'
            sortOptions={sortOptions}
            sortBy={sortBy}
            handleSortChange={handleSortChange}
          />
        </>
      )}
    </article>
  )
}
