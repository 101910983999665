export default function gradYearToGrade(gradYear) {
  const now = new Date()
  let grade

  if (now.getMonth().isNaN) return '—'
  else if (now.getMonth() < 7) grade = now.getFullYear() + 12 - gradYear
  else grade = now.getFullYear() + 13 - gradYear

  if (grade < 0) return '—'
  else if (grade > 12) return 'Alumni'
  else return grade
}
