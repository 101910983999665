import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import Card from './Card'
import LabeledText from './LabeledText'
import SmallButton from './SmallButton'

export default function ClubCard({club}) {
  return (
    <Card className='club-card'>
      <h3 className='club-card__name'>{club.name}</h3>
      <LabeledText
        className='labeled-text--cc'
        text={club.carryover + club.new}
        label='Points'
      />
      <Link to={`/member/clubs/${club.id}/details`}>
        <SmallButton className='small-button--margin' text='Details' />
      </Link>
    </Card>
  )
}

ClubCard.propTypes = {
  club: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    carryover: PropTypes.number.isRequired,
    new: PropTypes.number.isRequired
  })
}
