import React from 'react'
import {Routes, Route} from 'react-router-dom'

import Dispatch from './components/Dispatch'
import Login from './pages/auth/Login'
import CreateAccount from './pages/auth/CreateAccount'
import ResetPassword from './pages/auth/ResetPassword'
import RequireAuth from './components/RequireAuth'
import MemberLayout from './layouts/MemberLayout'
import Clubs from './pages/clubs/Clubs'
import ClubDetails from './pages/clubs/ClubDetails'
import Account from './pages/account/Account'
import CheckIn from './pages/checkin/CheckIn'
import AdminLayout from './layouts/AdminLayout'
import Events from './pages/events/Events'
import EventDetails from './pages/events/EventDetails'
import EventEdit from './pages/events/EventEdit'
import EventCreate from './pages/events/EventCreate'
import Members from './pages/members/Members'
import PageNotFound from './pages/fallback/PageNotFound'
import PrivacyPolicy from './pages/legal/PrivacyPolicy'
import TermsOfService from './pages/legal/TermsOfService'

export default function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Dispatch />} />
        <Route path='/login' element={<Login />} />
        <Route path='/create-account' element={<CreateAccount />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-service' element={<TermsOfService />} />
        <Route path='/member' element={<RequireAuth allowedRole='member' />}>
          <Route
            path='clubs'
            element={
              <MemberLayout>
                <Clubs />
              </MemberLayout>
            }
          />
          <Route
            path='clubs/:clubId/details'
            element={
              <MemberLayout>
                <ClubDetails />
              </MemberLayout>
            }
          />
          <Route
            path='account'
            element={
              <MemberLayout>
                <Account />
              </MemberLayout>
            }
          />
          <Route path='checkin' element={<CheckIn />} />
        </Route>
        <Route path='/admin' element={<RequireAuth allowedRole='admin' />}>
          <Route
            path='events'
            element={
              <AdminLayout>
                <Events />
              </AdminLayout>
            }
          />
          <Route
            path='events/create'
            element={
              <AdminLayout>
                <EventCreate />
              </AdminLayout>
            }
          />
          <Route
            path='events/:eventId/details'
            element={
              <AdminLayout>
                <EventDetails />
              </AdminLayout>
            }
          />
          <Route
            path='events/:eventId/edit'
            element={
              <AdminLayout>
                <EventEdit />
              </AdminLayout>
            }
          />
          <Route
            path='members'
            element={
              <AdminLayout>
                <Members />
              </AdminLayout>
            }
          />
          <Route
            path='account'
            element={
              <AdminLayout>
                <Account />
              </AdminLayout>
            }
          />
        </Route>
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </div>
  )
}
