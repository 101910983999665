import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

export default function AuthLink({className, to, state, label}) {
  return (
    <Link
      to={to}
      state={state ? state : null}
      className={'auth-link' + (className ? ` ${className}` : '')}
    >
      {label}
    </Link>
  )
}

AuthLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  state: PropTypes.shape({from: PropTypes.string}),
  label: PropTypes.string.isRequired
}
