import React from 'react'

import FullScreenContainer from '../../components/FullScreenContainer'
import Table from '../../components/Table'

export default function PrivacyPolicy() {
  const tableData = [
    {
      category: 'A. Identifiers',
      examples:
        'Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name',
      collected: 'YES'
    },
    {
      category:
        'B. Personal information categories listed in the California Customer Records statute',
      examples:
        'Name, contact information, education, employment, employment history, and financial information',
      collected: 'YES'
    },
    {
      category:
        'C. Protected classification characteristics under California or federal law',
      examples: 'Gender and date of birth',
      collected: 'YES'
    },
    {
      category: 'D. Commercial information',
      examples:
        'Transaction information, purchase history, financial details, and payment information',
      collected: 'NO'
    },
    {
      category: 'E. Biometric information',
      examples: 'Fingerprint and voiceprints',
      collected: 'NO'
    },
    {
      category: 'F. Internet or other similar network activity',
      examples:
        'Browsing history, search history, online behavior, interest data, 			and interactions with our and other websites, applications, systems, and advertisements',
      collected: 'NO'
    },
    {
      category: 'G. Geolocation data',
      examples: 'Device location',
      collected: 'NO'
    },
    {
      category:
        'H. Audio, electronic, visual, thermal, olfactory, or other similar information',
      examples:
        'Images and audio, video or call recordings created in connection	with our business activities',
      collected: 'NO'
    },
    {
      category: 'I. Professional or employment-related information',
      examples:
        'Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us',
      collected: 'NO'
    },
    {
      category: 'J. Education information',
      examples: 'Student records and directory information',
      collected: 'YES'
    },
    {
      category: 'K. Inferences drawn from other personal information',
      examples:
        "Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an	individual's preferences and characteristics",
      collected: 'NO'
    },
    {
      category: 'L. Sensitive Personal Information',
      examples: 'Account login information',
      collected: 'YES'
    }
  ]

  return (
    <FullScreenContainer className='full-screen-container--wide privacy-policy'>
      <h1 className='privacy-policy__h1'>Privacy Policy</h1>
      <strong className='privacy-policy__subtitle'>
        Last updated September 6, 2023
      </strong>
      <p className='privacy-policy__p'>
        This privacy notice for Gavel &#40;<strong>&quot;we</strong>,
        <strong>&quot;</strong> <strong>&quot;us</strong>,
        <strong>&quot;</strong> or <strong>&quot;our&quot;</strong>&#41;,
        describes how and why we might collect, store, use, and/or share &#40;
        <strong>&quot;process&quot;</strong>&#41; your information when you use
        our services &#40;<strong>&quot;Services&quot;</strong>&#41;, such as
        when you:{' '}
      </p>
      <ul>
        <li>
          Visit our website at{' '}
          <a
            className='privacy-policy__link'
            href='https://www.gavelapp.in'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.gavelapp.in
          </a>
          , or any website of ours that links to this privacy notice
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>
      <p className='privacy-policy__p'>
        <strong>Questions or concerns? </strong>Reading this privacy notice will
        help you understand your privacy rights and choices. If you do not agree
        with our policies and practices, please do not use our Services. If you
        still have any questions or concerns, please contact us at{' '}
        <a
          className='privacy-policy__link'
          href='mailto:gavelhelp@gmail.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          gavelhelp@gmail.com
        </a>
      </p>
      <h2 className='privacy-policy__h2'>1. What Information Do We Collect?</h2>
      <h3 className='privacy-policy__h3'>
        Personal Information You Disclose to Us
      </h3>
      <p className='privacy-policy__p'>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>
      <p className='privacy-policy__p'>
        <strong>Personal Information Provided by You. </strong>The personal
        information that we collect depends on the context of your interactions
        with us and the Services, the choices you make, and the products and
        features you use. The personal information we collect may include the
        following:{' '}
      </p>
      <ul>
        <li>names</li>
        <li>email addresses</li>
        <li>passwords</li>
        <li>graduation year</li>
        <li>club event attendance history</li>
      </ul>
      <p className='privacy-policy__p'>
        <strong>Sensitive Information. </strong>When necessary, with your
        consent or otherwise permitted by applicable law, we process the
        following categories of sensitive information:{' '}
      </p>
      <ul>
        <li>student data</li>
      </ul>
      <p className='privacy-policy__p'>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>
      <h2 className='privacy-policy__h2'>
        2. How Do We Process Your Information?
      </h2>
      <strong>
        We process your information for a variety of reasons, depending on how
        you interact with our Services, including:{' '}
      </strong>
      <ul>
        <li>
          <strong>
            To facilitate account creation and authentication and otherwise
            manage user accounts.{' '}
          </strong>
          We may process your information so you can create and log in to your
          account, as well as keep your account in working order.
        </li>
        <li>
          <strong>
            To deliver and facilitate delivery of services to the user.{' '}
          </strong>
          We may process your information to provide you with the requested
          service.
        </li>
        <li>
          <strong>To respond to user inquiries/offer support to users. </strong>
          We may process your information to respond to your inquiries and solve
          any potential issues you might have with the requested service.
        </li>
        <li>
          <strong>To send administrative information to you. </strong>We may
          process your information to send you details about our products and
          services, changes to our terms and policies, and other similar
          information.
        </li>
        <li>
          <strong>
            To evaluate and improve our Services, products, marketing, and your
            experience.{' '}
          </strong>
          We may process your information when we believe it is necessary to
          identify usage trends, determine the effectiveness of our promotional
          campaigns, and to evaluate and improve our Services, products,
          marketing, and your experience.
        </li>
        <li>
          <strong>To identify usage trends. </strong>We may process information
          about how you use our Services to better understand how they are being
          used so we can improve them.
        </li>
      </ul>
      <h2 className='privacy-policy__h2'>
        3. When and With Whom Do We Share Your Personal Information?
      </h2>
      <p className='privacy-policy__p'>
        We may need to share your personal information in the following
        situations:{' '}
      </p>
      <ul>
        <li>
          <strong>Business Transfers. </strong>We may share or transfer your
          information in connecting with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </li>
      </ul>
      <h2 className='privacy-policy__h2'>
        4. How Long Do We Keep Your Information?
      </h2>
      <p className='privacy-policy__p'>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer period is required or permitted by law &#40;such as tax,
        accounting, or other legal requirements&#41;. No purpose in this notice
        will require us keeping your personal information for longer than six
        &#40;6&#41; months past the termination of the user's account.
      </p>
      <p className='privacy-policy__p'>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible &#40;for example, because your
        personal information has been stored in backup archives&#41;, when we
        will securely store your personal information and isolate it from any
        further processing until deletion is possible.
      </p>
      <h2 className='privacy-policy__h2'>
        5. How Do We Keep Your Information Safe?
      </h2>
      <p className='privacy-policy__p'>
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage can be guaranteed to be 100% secure, so
        we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security and
        improperly collect, access, steal, or modify your information. Although
        we will do our best to protect your personal information, transmission
        of personal information to and from our Services is at your own risk.
        You should only access the Services within a safe environment.
      </p>
      <h2 className='privacy-policy__h2'>6. What Are Your Privacy Rights?</h2>
      <p className='privacy-policy__p'>
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your{' '}
        <a
          className='privacy-policy__link'
          href='https://ec.europa.eu/newsroom/article29/items/612080'
          target='_blank'
          rel='noopener noreferrer'
        >
          Member State data protection authority
        </a>{' '}
        or{' '}
        <a
          className='privacy-policy__link'
          href='https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/'
          target='_blank'
          rel='noopener noreferrer'
        >
          UK data protection authority
        </a>
        .
      </p>
      <p className='privacy-policy__p'>
        If you are located in Switzerland, you may contact the{' '}
        <a
          className='privacy-policy__link'
          href='https://www.edoeb.admin.ch/edoeb/en/home.html'
          target='_blank'
          rel='noopener noreferrer'
        >
          Federal Data Protection and Information Commissioner
        </a>
        .
      </p>
      <p className='privacy-policy__p'>
        <strong>Withdrawing your consent:</strong> If we are relying on your
        consent to process your personal information, which may be express
        and/or implied consent at any time. You can withdraw your consent at any
        time by contacting us by using the contact details provided in the
        section &quot;How Can You Contact Us About This Notice?&quot; below.
      </p>
      <p className='privacy-policy__p'>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable allows, will it
        affect the processing of your personal information conducted in reliance
        on lawful processing grounds other than consent.
      </p>
      <h3 className='privacy-policy__h3'>Account Information</h3>
      <p className='privacy-policy__p'>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can contact us using the
        contact information provided in the section &quot;How Can You Contact Us
        About This Notice?&quot; below.
      </p>
      <p className='privacy-policy__p'>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.
      </p>
      <p className='privacy-policy__p'>
        If you have questions or comments about your privacy rights, you may
        email us at{' '}
        <a
          className='privacy-policy__link'
          href='mailto:gavelhelp@gmail.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          gavelhelp@gmail.com
        </a>
      </p>
      <h2 className='privacy-policy__h2'>
        7. Controls for Do-Not-Track Features
      </h2>
      <p className='privacy-policy__p'>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track &#40;&quot;DNT&quot;&#41; feature or
        setting you can activate to signal your privacy preference not to have
        data about your online browsing activities monitored and collected. At
        this stage no uniform technology standard for recognizing and
        implementing DNT signals has been finalized. As such, we do not
        currently respond to DNT browser signals or any other mechanism that
        automatically communicates your choice not to be tracked online. If a
        standard for online tracking is adopted that we must follow in the
        future, we will inform you about that practice in a revised version of
        this privacy notice.
      </p>
      <h2 className='privacy-policy__h2'>
        8. How Do We Protect the Privacy of Children Online?
      </h2>
      <p className='privacy-policy__p'>
        We acknowledge the Children's Online Privacy Protection Act. Our
        Services are not directed to children under the age of 13 and do not
        knowingly collect personal information from children under the age of
        13.
      </p>
      <p className='privacy-policy__p'>
        Please email{' '}
        <a
          className='privacy-policy__link'
          href='mailto:gavelhelp@gmail.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          gavelhelp@gmail.com
        </a>{' '}
        or refer to the contact information provided in the section &quot;How
        Can You Contact Us About This Notice?&quot; below if you have concerns
        regarding the potential collection of your child's information.
      </p>
      <h2 className='privacy-policy__h2'>
        9. Do California Residents Have Specific Privacy Rights?
      </h2>
      <p className='privacy-policy__p'>
        California Civil Code Section 1798.83, also known as the &quot;Shine The
        Light&quot; law, permits our users who are California residents to
        request and obtain from us, once a year and free of charge, information
        about categories of personal information &#40;if any&#41; we disclosed
        to third parties for direct marketing purposes and the names and
        addresses of all third parties with which we shared personal information
        in the immediately preceding calendar year. If you are a California
        resident and would like to make such a request, please submit your
        request in writing to us using the contact information provided in the
        section &quot;How Can You Contact Us About This Notice?&quot; below.
      </p>
      <p className='privacy-policy__p'>
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided in the section &quot;How Can You Contact Us About This
        Notice?&quot; below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems &#40;e.g., backups, etc.&#41;.
      </p>
      <h3 className='privacy-policy__h3'>CCPA Privacy Notice</h3>
      <p className='privacy-policy__p'>
        The California Code of Regulations defines a &quot;resident&quot; as:{' '}
      </p>
      <ol>
        <li>
          every individual who is in the State of California for other than a
          temporary or transitory purpose and
        </li>
        <li>
          every individual who is domiciled in the State of California who is
          outside the State of California for a temporary or transitory purpose
        </li>
      </ol>
      <p className='privacy-policy__p'>
        All other individuals are defined as &quot;non-residents.&quot;
      </p>
      <p className='privacy-policy__p'>
        If this definition of &quot;resident&quot; applies to you, we must
        adhere to certain rights and obligations regarding your personal
        information.
      </p>
      <h4 className='privacy-policy__h4'>
        What categories of personal information do we collect?
      </h4>
      <p className='privacy-policy__p'>
        We have collected the following categories of personal information in
        the past twelve &#40;12&#41; months:{' '}
      </p>
      <Table
        className='table--white'
        head={['Category', 'Examples', 'Collected']}
        dataLabels={['category', 'examples', 'collected']}
        data={tableData}
      />
      <p className='privacy-policy__p'>
        We will use and retain the collected personal information as needed to
        provide the Services or for:{' '}
      </p>
      <ul>
        <li>Category A - As long as the user has an account with us</li>
        <li>Category B - As long as the user has an account with us</li>
        <li>Category C - As long as the user has an account with us</li>
        <li>Category J - As long as the user has an account with us</li>
        <li>Category L - As long as the user has an account with us</li>
      </ul>
      <p className='privacy-policy__p'>
        Category L information may be used, or disclosed to a service provider
        or contractor, for additional, specified purposes. You have the right to
        limit the use or disclosure or your sensitive personal information.
      </p>
      <p className='privacy-policy__p'>
        We may also collect other personal information outside of these
        categories through instances where you interact with us in person,
        online, or by phone or mail in the context of:{' '}
      </p>
      <ul>
        <li>Receiving help through our customer support channels;</li>
        <li>Participation in customer surveys or contests; and</li>
        <li>
          Facilitation in the delivery of our Services and to respond to your
          inquiries.
        </li>
      </ul>
      <h4 className='privacy-policy__h4'>
        How do we use and share your personal information?
      </h4>
      <p className='privacy-policy__p'>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>
      <p className='privacy-policy__p'>
        You may contact us by email at{' '}
        <a
          className='privacy-policy__link'
          href='mailto:gavelhelp@gmail.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          gavelhelp@gmail.com
        </a>
        , or by referring to the contact information provided in the section
        &quot;How Can You Contact Us About This Notice?&quot; below.
      </p>
      <p className='privacy-policy__p'>
        If you are using an authorized agent to exercise your right to opt out,
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.
      </p>
      <h4 className='privacy-policy__h4'>
        Will your information be shared with anyone else?
      </h4>
      <p className='privacy-policy__p'>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf, following the same strict privacy protection
        obligations mandated by the CCPA.
      </p>
      <p className='privacy-policy__p'>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be &quot;selling&quot; of your
        personal information.
      </p>
      <p className='privacy-policy__p'>
        We have not disclosed, sold, or shared any personal information to third
        parties for a business or commercial purpose in the preceding twelve
        &#40;12&#41; months. We will not sell or share personal information in
        the future belonging to website visitors, users, and other consumers.
      </p>
      <h4 className='privacy-policy__h4'>
        Your rights with respect to your personal data
      </h4>
      <h5 className='privacy-policy__h5'>
        Right to request deletion of the data — Request to delete
      </h5>
      <p className='privacy-policy__p'>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as &#40;but not limited to&#41; the exercise by another
        consumer of his or her right to free speech, our compliance requirements
        resulting from a legal obligation, or any processing that may be
        required to protect against illegal activities.
      </p>
      <h5 className='privacy-policy__h5'>
        Depending on the circumstances, you have the right to know:{' '}
      </h5>
      <ul>
        <li>whether we collect and use your personal information;</li>
        <li>the categories of personal information that we collect;</li>
        <li>
          the purposes for which the collected personal information is used;
        </li>
        <li>whether we sell or share personal information to third parties;</li>
        <li>
          the categories of personal information that we sold, shared, or
          disclosed for a business purpose;
        </li>
        <li>
          the categories of third parties to whom the personal information was
          sold, shared, or disclosed for a business purpose;
        </li>
        <li>
          the business or commercial purpose for collecting, selling, or sharing
          personal information; and
        </li>
        <li>
          the specific pieces of personal information we collected about you.
        </li>
      </ul>
      <p className='privacy-policy__p'>
        In accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>
      <h5 className='privacy-policy__h5'>
        Right to Non-Discrimination for the Exercise of a Consumer's Privacy
        Rights
      </h5>
      <p className='privacy-policy__p'>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>
      <h5 className='privacy-policy__h5'>
        Right to Limit Use and Disclosure of Sensitive Personal Information
      </h5>
      <p className='privacy-policy__p'>
        If the business collects any of the following:{' '}
      </p>
      <ul>
        <li>
          social security information, drivers' licenses, state ID cards,
          passport numbers
        </li>
        <li>account login information</li>
        <li>
          credit card numbers, financial account information, or credentials
          allowing access to such accounts
        </li>
        <li>precise geolocation</li>
        <li>
          racial or ethnic origin, religion or philosophical beliefs, union
          membership
        </li>
        <li>
          the contents of email and text, unless the business is the intended
          recipient of the communication
        </li>
        <li>genetic data, biometric data, and health data</li>
        <li>data concerning sexual orientation and sex life</li>
      </ul>
      <p className='privacy-policy__p'>
        you have the right to direct that business to limit its use of your
        sensitive personal information to that use which is necessary to perform
        the Services.
      </p>
      <p className='privacy-policy__p'>
        Once a business receives your request, they are no longer allowed to use
        or disclose your sensitive personal information for any other purpose
        unless you provide consent for the use or disclosure of sensitive
        personal information for additional purposes.
      </p>
      <p className='privacy-policy__p'>
        Please note that sensitive personal information that is collected or
        processed without the purpose of inferring characteristics about a
        consumer is not covered by this right, as well as the publicly available
        information.
      </p>
      <p className='privacy-policy__p'>
        To exercise your right to limit use and disclosure of sensitive personal
        information, please email{' '}
        <a
          className='privacy-policy__link'
          href='mailto:gavelhelp@gmail.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          gavelhelp@gmail.com
        </a>
        .
      </p>
      <h5 className='privacy-policy__h5'>Verification process</h5>
      <p className='privacy-policy__p'>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method &#40;e.g., phone or
        email&#41; that you have previously provided to us. We may also use
        other verification methods as the circumstances dictate.
      </p>
      <p className='privacy-policy__p'>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>
      <h5 className='privacy-policy__h5'>Other privacy rights</h5>
      <ul>
        <li>You may object to the processing of your personal information.</li>
        <li>
          You may request correction of your personal data if it is incorrect or
          no longer relevant, or ask to restrict the processing of the
          information.
        </li>
        <li>
          You can designate an authorized agent to make a request under the CCPA
          on your behalf. We may deny a request from an authorized agent that
          does not submit proof that they have been validly authorized to act on
          your behalf in accordance with the CCPA.
        </li>
        <li>
          You may request to opt out from future selling or sharing of your
          personal information to third parties. Upon receiving an opt-out
          request, we will act upon the request as soon as feasibly possible,
          but no later than fifteen &#40;15&#41; days from the date of the
          request submission.
        </li>
      </ul>
      <p className='privacy-policy__p'>
        To exercise these rights, you can contact us by email at{' '}
        <a
          className='privacy-policy__link'
          href='mailto:gavelhelp@gmail.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          gavelhelp@gmail.com
        </a>
        , or by referring to the contact information provided in the section
        &quot;How Can You Contact Us About This Notice?&quot; below. If you have
        a complaint about how we handle your data, we would like to hear from
        you.
      </p>
      <h2 className='privacy-policy__h2'>
        10. Do Virginia Residents Have Specific Privacy Rights?
      </h2>
      <h3 className='privacy-policy__h3'>Virginia CDPA Privacy Notice</h3>
      <p className='privacy-policy__p'>
        Under the Virginia Consumer Data Protection Act &#40;CDPA&#41;:{' '}
      </p>
      <p className='privacy-policy__p'>
        &quot;Consumer&quot; means a natural person who is a resident of the
        Commonwealth acting only in an individual or household context. It does
        not include a natural person acting in a commercial or employment
        context.
      </p>
      <p className='privacy-policy__p'>
        &quot;Personal data&quot; means any information that is linked or
        reasonably linkable to an identified or identifiable natural person.
        &quot;Personal data&quot; does not include de-identified data or
        publicly available information.
      </p>
      <p className='privacy-policy__p'>
        &quot;Sale of personal data&quot; means the exchange of personal data
        for monetary consideration.
      </p>
      <p className='privacy-policy__p'>
        If this definition &quot;consumer&quot; applies to you, we must adhere
        to certain rights and obligations regarding your personal data.
      </p>
      <p className='privacy-policy__p'>
        The information we collect, use, and disclose about you will vary
        depending on how you interact with us and our Services. To find out
        more, please read the sections:{' '}
      </p>
      <ul>
        <li>&quot;What Information Do We Collect?&quot; above</li>
        <li>&quot;How Do We Process Your Information?&quot; above</li>
        <li>
          &quot;When and With Whom Do We Share Your Personal Information?&quot;
          above
        </li>
      </ul>
      <h4 className='privacy-policy__h4'>
        Your rights with respect to your personal data
      </h4>
      <ul>
        <li>
          Right to be informed whether or not we are processing your personal
          data
        </li>
        <li>Right to access your personal data</li>
        <li>Right to correct inaccuracies in your personal data</li>
        <li>Right to request deletion of your personal data</li>
        <li>
          Right to obtain a copy of the personal data you previously shared with
          us
        </li>
        <li>
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising, the sale of personal data, or profiling in
          furtherance of decisions that produce legal or similarly significant
          effects &#40;&quot;profiling&quot;&#41;
        </li>
      </ul>
      <p className='privacy-policy__p'>
        We have not sold any personal data to third parties for business or
        commercial purposes. We will not sell personal data in the future
        belonging to website visitors, users, and other consumers.
      </p>
      <h4 className='privacy-policy__h4'>
        Exercise your rights provided under the Virginia CDPA
      </h4>
      <p className='privacy-policy__p'>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>
      <p className='privacy-policy__p'>
        You may contact us by email at{' '}
        <a
          className='privacy-policy__link'
          href='mailto:gavelhelp@gmail.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          gavelhelp@gmail.com
        </a>
        , or by referring to the contact information provided in the section
        &quot;How Can You Contact Us About This Notice?&quot; below.
      </p>
      <p className='privacy-policy__p'>
        If you are using an authorized agent to exercise your rights, we may
        deny a request if the authorized agent does not submit proof that they
        have been validly authorized to act on your behalf.
      </p>
      <h4 className='privacy-policy__h4'>Verification process</h4>
      <p className='privacy-policy__p'>
        We may request that you provide additional information reasonably
        necessary to verify you and your consumer's request. If you submit the
        request through and authorized agent, we may need to collect additional
        information to verify your identity before processing your request.
      </p>
      <p className='privacy-policy__p'>
        Upon receiving your request, we will respond without undue delay, but in
        all cases, within forty-five &#40;45&#41; days of receipt. The response
        period may be extended once by forty-five &#40;45&#41; additional days
        when reasonably necessary. We will inform you of any such extension
        within the initial 45-day response period, together with the reason for
        the extension.
      </p>
      <h4 className='privacy-policy__h4'>Right to appeal</h4>
      <p className='privacy-policy__p'>
        If we decline to take action regarding your request, we will inform you
        of our decision and reasoning behind it. If you wish to appeal our
        decision, please email us at{' '}
        <a
          className='privacy-policy__link'
          href='mailto:gavelhelp@gmail.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          gavelhelp@gmail.com
        </a>
        . Within sixty &#40;60&#41; days of receipt of an appeal, we will inform
        you in writing of any action taken or not taken in response to the
        appeal, including a written explanation of the reasons for the
        decisions. If your appeal is denied, you may contact the{' '}
        <a
          className='privacy-policy__link'
          href='https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint'
          target='_blank'
          rel='noopener noreferrer'
        >
          Attorney General to submit a complaint
        </a>
        .
      </p>
      <h2 className='privacy-policy__h2'>
        11. Do We Make Updates to This Notice?
      </h2>
      <p className='privacy-policy__p'>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated &quot;Revised&quot; date and the updated
        version will be effective as soon as it is accessible. If we make
        material changes to this privacy notice, we may notify you either by
        prominently posting a notice of such changes or by directly sending you
        a notification. We encourage you to review this privacy notice
        frequently to be informed of how we are protecting your information.
      </p>
      <h2 className='privacy-policy__h2'>
        12. How Can You Contact Us About This Notice?
      </h2>
      <p className='privacy-policy__p'>
        If you have questions or comments about this notice, you may email us at{' '}
        <a
          className='privacy-policy__link'
          href='mailto:gavelhelp@gmail.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          gavelhelp@gmail.com
        </a>
        .
      </p>
      <h2 className='privacy-policy__h2'>
        13. How Can You Review, Update, or Delete the Data We Collect from You?
      </h2>
      <p className='privacy-policy__p'>
        Based on applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please email{' '}
        <a
          className='privacy-policy__link'
          href='mailto:gavelhelp@gmail.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          gavelhelp@gmail.com
        </a>
        .
      </p>
      <p className='privacy-policy__p'>
        This privacy policy was created using Termly's{' '}
        <a
          className='privacy-policy__link'
          href='https://termly.io/products/privacy-policy-generator/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Privacy Policy Generator
        </a>
      </p>
    </FullScreenContainer>
  )
}
