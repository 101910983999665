import React, {useEffect} from 'react'
import {doc, getDoc} from 'firebase/firestore'
import {useNavigate, Navigate} from 'react-router-dom'
import {db} from '../firebase'
import {useAuth} from '../contexts/AuthContext'

import LoadingSpinner from './LoadingSpinner'
import LandingPage from '../pages/landing-page/LandingPage'

export default function Dispatch() {
  const {user, setUser} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      getDoc(doc(db, 'members', user.uid))
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            setUser((prevUser) => ({
              ...prevUser,
              accountType: 'member'
            }))
            navigate('/member/clubs')
          } else {
            getDoc(doc(db, 'admins', user.uid)).then((docSnapshot) => {
              if (docSnapshot.exists()) {
                setUser((prevUser) => ({
                  ...prevUser,
                  accountType: 'admin',
                  ...docSnapshot.data()
                }))
                navigate('/admin/events')
              }
            })
          }
        })
        .catch((err) => console.log(err.message))
    }
  }, [])

  return user ? (
    <LoadingSpinner className='loading-spinner--fullScreen' />
  ) : (
    <LandingPage />
  )
}
