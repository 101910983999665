import React from 'react'
import PropTypes from 'prop-types'

import IconButton from './IconButton'
import {ReactComponent as SaveIcon} from '../icons/save.svg'
import {ReactComponent as CloseIcon} from '../icons/close.svg'

export default function SaveCloseButtons({
  tooltip,
  keyboardState,
  showSave,
  handleSave,
  showClose,
  handleClose
}) {
  return (
    <div
      className={
        'save-close-buttons' +
        (keyboardState ? ` save-close-buttons--${keyboardState}` : '')
      }
    >
      {tooltip && (
        <div className='save-close-buttons__saveTooltip'>
          <p>Shortcut: ⇧ + ⏎</p>
        </div>
      )}
      {showSave && (
        <IconButton
          className='save-close-buttons__saveButton'
          onClick={handleSave}
        >
          <SaveIcon
            className='save-close-buttons__icon'
            alt='Save Icon - White'
          />
        </IconButton>
      )}
      {showClose && (
        <IconButton className='icon-button--lightbeige' onClick={handleClose}>
          <CloseIcon
            className='save-close-buttons__icon'
            alt='Close Icon - Light Brown'
          />
        </IconButton>
      )}
    </div>
  )
}

SaveCloseButtons.defaultProps = {
  tooltip: false,
  keyboardState: '',
  showSave: true,
  showClose: true
}

SaveCloseButtons.propTypes = {
  tooltip: PropTypes.bool,
  keyboardState: PropTypes.string,
  showSave: PropTypes.bool,
  handleSave: PropTypes.func.isRequired,
  showClose: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
}
