import React from 'react'
import PropTypes from 'prop-types'

export default function FullScreenContainer({children, className}) {
  return (
    <div
      className={'full-screen-container' + (className ? ` ${className}` : '')}
    >
      <div className='full-screen-container__container'>{children}</div>
    </div>
  )
}

FullScreenContainer.propTypes = {
  className: PropTypes.string
}
