import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCN4DdwuXZEdSYRDP0mCuuIPlIDuUzslho',
  authDomain: 'gavel-ae2c1.firebaseapp.com',
  projectId: 'gavel-ae2c1',
  storageBucket: 'gavel-ae2c1.appspot.com',
  messagingSenderId: '702362345845',
  appId: '1:702362345845:web:d0215353f09be47454733c'
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)

export {db, auth}
