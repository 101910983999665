import React, {useState} from 'react'
import {useAuth} from '../../contexts/AuthContext'

import {ReactComponent as GavelLogo} from '../../icons/gavel-logo.svg'

import FullScreenContainer from '../../components/FullScreenContainer'
import StatusMessage from '../../components/StatusMessage'
import TextInput from '../../components/TextInput'
import AuthButton from '../../components/AuthButton'
import AuthLink from '../../components/AuthLink'

export default function ResetPassword() {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [status, setStatus] = useState('')

  const {resetPassword} = useAuth()

  function handleChange(e) {
    const {value} = e.target
    setEmail(value)
  }

  function handleResetPassword(e) {
    e.preventDefault()
    setLoading(true)
    resetPassword(email)
      .then(() => setStatus('Check your email for further instructions.'))
      .catch(err => {
        console.log(err.message)
        setError('Failed to reset password.')
      })
      .finally(() => setLoading(false))
  }

  return (
    <FullScreenContainer className='reset-password'>
      <GavelLogo className='reset-password__logo' alt='Gavel Logo - Color' />
      {error && <StatusMessage type='error'>{error}</StatusMessage>}
      {status && <StatusMessage>{status}</StatusMessage>}
      <form className='reset-password__form' onSubmit={handleResetPassword}>
        <TextInput
          className='text-input--auth'
          type='email'
          required={true}
          label='Email Address'
          value={email}
          name='email'
          handleChange={handleChange}
        />
        <AuthButton
          label={loading ? 'Processing...' : 'Reset Password'}
          disabled={loading}
        />
      </form>
      <AuthLink className='auth-link--beige' to='/login' label='Cancel' />
    </FullScreenContainer>
  )
}
