import React, {useState, useEffect, useRef} from 'react'
import {Link, useParams} from 'react-router-dom'
import {
  doc,
  onSnapshot,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  serverTimestamp,
  increment
} from 'firebase/firestore'
import {db} from '../../firebase'
import {useAuth} from '../../contexts/AuthContext'
import gradYearToGrade from '../../util/gradYearToGrade'

import Header from '../../components/Header'
import SmallButton from '../../components/SmallButton'
import LabeledText from '../../components/LabeledText'
import Table from '../../components/Table'
import LoadingSpinner from '../../components/LoadingSpinner'

export default function EventDetails() {
  const {eventId} = useParams()

  const [event, setEvent] = useState({})
  const [sortBy, setSortBy] = useState('Timestamp: Newest-Oldest')
  const [addAttendee, setAddAttendee] = useState({
    value: '',
    timestamp: '',
    name: '',
    gradYear: ''
  })
  const [addLoading, setAddLoading] = useState(false)
  const [members, setMembers] = useState(null)
  const [inputFocus, setInputFocus] = useState(false)
  const [keyboardState, setKeyboardState] = useState('')
  const [loading, setLoading] = useState(true)

  const selectSearchRef = useRef(null)

  const {user} = useAuth()

  useEffect(() => {
    const docRef = doc(db, 'clubs', user.clubId, 'events', eventId)
    const unsubDoc = onSnapshot(docRef, docSnapshot => {
      const eventData = {id: docSnapshot.id, ...docSnapshot.data()}
      // Convert attendees from Object to Array
      const attendeeKeys = eventData.attendees
        ? Object.keys(eventData.attendees)
        : []
      const attendeesArr = attendeeKeys.map(key => ({
        id: key,
        ...eventData.attendees[key]
      }))
      if (!attendeesArr.find(attendee => attendee.timestamp === null)) {
        eventData.attendees = attendeesArr.sort(
          (a, b) =>
            b.timestamp.seconds - a.timestamp.seconds ||
            a.name.localeCompare(b.name)
        )

        setEvent(eventData)
        setLoading(false)
      }
    })

    return unsubDoc
  }, [])

  useEffect(() => {
    if (members !== null) {
      setMembers(prevMembers =>
        prevMembers.filter(
          prevMember =>
            !event.attendees.find(attendee => attendee.id === prevMember.value)
        )
      )
    }
  }, [event.attendees])

  useEffect(() => {
    function handleShiftKeydown(e) {
      if (e.shiftKey) setKeyboardState('shift')
    }

    function handleShiftKeyup(e) {
      if (e.key === 'Shift') setKeyboardState('')
    }

    function handleShiftEnterKeydown(e) {
      if (e.shiftKey && e.key === 'Enter') {
        setKeyboardState('shiftEnter')
        handleAddSave()
      }
    }

    if (addAttendee.value && !inputFocus) {
      document.addEventListener('keydown', handleShiftKeydown)
      document.addEventListener('keyup', handleShiftKeyup)
      document.addEventListener('keydown', handleShiftEnterKeydown)
    } else {
      setKeyboardState('')
      document.removeEventListener('keydown', handleShiftKeydown)
      document.removeEventListener('keyup', handleShiftKeyup)
      document.removeEventListener('keydown', handleShiftEnterKeydown)
    }

    return () => {
      document.removeEventListener('keydown', handleShiftKeydown)
      document.removeEventListener('keyup', handleShiftKeyup)
      document.removeEventListener('keydown', handleShiftEnterKeydown)
    }
  }, [addAttendee.value, inputFocus])

  useEffect(() => {
    if (addAttendee.timestamp && !addLoading && members?.length) {
      // Focus on input
      selectSearchRef.current.firstChild.firstChild.focus()
    }
  }, [addAttendee, addLoading, members])

  const sortOptions = [
    'Timestamp: Newest-Oldest',
    'Timestamp: Oldest-Newest',
    'Name: A-Z',
    'Name: Z-A',
    'Grade: Increasing',
    'Grade: Decreasing'
  ]

  function sortAttendees(attendeesArr, sortBy) {
    return attendeesArr.toSorted((a, b) => {
      switch (sortBy) {
        case 'Timestamp: Oldest-Newest':
          return (
            a.timestamp.seconds - b.timestamp.seconds ||
            a.name.localeCompare(b.name)
          )
        case 'Name: A-Z':
          return (
            a.name.localeCompare(b.name) ||
            b.timestamp.seconds - a.timestamp.seconds
          )
        case 'Name: Z-A':
          return (
            b.name.localeCompare(a.name) ||
            b.timestamp.seconds - a.timestamp.seconds
          )
        case 'Grade: Increasing':
          return (
            b.gradYear - a.gradYear || b.timestamp.seconds - a.timestamp.seconds
          )
        case 'Grade: Decreasing':
          return (
            a.gradYear - b.gradYear || b.timestamp.seconds - a.timestamp.seconds
          )
        default:
          return (
            b.timestamp.seconds - a.timestamp.seconds ||
            a.name.localeCompare(b.name)
          )
      }
    })
  }

  function handleSortChange(e) {
    const newSortBy = e.target.value
    setSortBy(newSortBy)
    setEvent(prevEvent => {
      const newAttendeesArr = sortAttendees(event.attendees, newSortBy)
      return {
        ...prevEvent,
        attendees: newAttendeesArr
      }
    })
  }

  function fetchMembers() {
    // Get members registered in club from members collection
    const membersColRef = collection(db, 'members')
    const q = query(membersColRef, where(`clubs.${user.clubId}`, '!=', null))
    getDocs(q)
      .then(snapshot => {
        const members = snapshot.docs
          .map(doc => ({
            value: doc.id,
            name: doc.data().name,
            gradYear: doc.data().gradYear
          }))
          .filter(
            member =>
              !event.attendees.find(attendee => attendee.id === member.value)
          )
          .sort((a, b) => a.name.localeCompare(b.name))
        setMembers(members)
      })
      .catch(err => console.log(err.message))
  }

  function handleAdd() {
    setAddAttendee(prevAddAttendee => {
      fetchMembers()
      return {
        ...prevAddAttendee,
        timestamp: 'Now'
      }
    })
  }

  function handleAddChange(value) {
    const addAttendee = members.find(member => member.value === value)
    setAddAttendee(prevAddAttendee => ({
      ...prevAddAttendee,
      value: value,
      name: addAttendee.name,
      gradYear: addAttendee.gradYear
    }))
  }

  function handleAddClose() {
    setAddAttendee({
      value: '',
      timestamp: '',
      name: '',
      gradYear: ''
    })
  }

  function handleAddSave() {
    setAddLoading(true)

    const eventDocRef = doc(db, 'clubs', user.clubId, 'events', eventId)
    const checkInPromises = []

    // Add member as an attendee to the events collection group
    checkInPromises.push(
      updateDoc(eventDocRef, {
        [`attendees.${addAttendee.value}`]: {
          timestamp: serverTimestamp(),
          name: addAttendee.name,
          gradYear: addAttendee.gradYear
        }
      })
    )

    const memberDocRef = doc(db, 'members', addAttendee.value)
    // Update points in members collection
    checkInPromises.push(
      updateDoc(memberDocRef, {
        [`clubs.${user.clubId}.new`]: increment(event.points)
      })
    )

    Promise.all(checkInPromises)
      .then(() => {
        setAddAttendee({
          value: '',
          timestamp: 'Now',
          name: '',
          gradYear: ''
        })
        setSortBy('Timestamp: Newest-Oldest')
        setAddLoading(false)
      })
      .catch(err => {
        console.log(err.message)
        setAddLoading(false)
      })
  }

  const date = new Date(event.date).toLocaleDateString('en-US', {
    timeZone: 'UTC'
  })

  function tableData() {
    return event.attendees.map(attendee => ({
      ...attendee,
      timestamp: new Date(attendee.timestamp.seconds * 1000)
        .toLocaleString('en-US')
        .replace(',', ''),
      grade: gradYearToGrade(attendee.gradYear)
    }))
  }

  return loading ? (
    <LoadingSpinner />
  ) : (
    <article className='event-details'>
      <Header title={`${event.name} Details`} />
      <Link to={`/admin/events/${event.id}/edit`}>
        <SmallButton className='small-button--md' text='Edit Event Details' />
      </Link>
      {event.status === 'Active' && (
        <div className='event-details__invite'>
          <h2 className='event-details__inviteTitle'>Member Invite</h2>
          <div className='event-details__inviteBox'>
            <p className='event-details__inviteText'>
              Login at: <strong>gavelapp.in/login</strong>
            </p>
            <p className='event-details__inviteText'>
              Submit Code: <strong>{event.code}</strong>
            </p>
          </div>
        </div>
      )}
      <div className='event-details__grid'>
        <LabeledText
          className='labeled-text--d'
          text={event.status}
          label='Active Status'
        />
        <LabeledText className='labeled-text--d' text={date} label='Date' />
        <LabeledText
          className='labeled-text--d'
          text={event.points}
          label='Points'
        />
        <LabeledText
          className='labeled-text--d'
          text={event.code}
          label='Code'
        />
      </div>
      <Table
        title={`${event.attendees.length} Attendee${
          event.attendees.length === 1 ? '' : 's'
        }`}
        head={['Timestamp', 'Name', 'Grade']}
        dataLabels={['timestamp', 'name', 'grade']}
        data={tableData()}
        noDataMessage='Attendees will show up here...'
        sortOptions={sortOptions}
        sortBy={sortBy}
        handleSortChange={handleSortChange}
        addData={addAttendee}
        addOptions={members}
        addLoading={addLoading}
        handleAdd={handleAdd}
        handleAddChange={handleAddChange}
        handleAddClose={handleAddClose}
        handleAddSave={handleAddSave}
        selectSearchRef={selectSearchRef}
        keyboardState={keyboardState}
        handleInputFocusChange={value => setInputFocus(value)}
      />
    </article>
  )
}
