import React from 'react'
import {Link} from 'react-router-dom'

import {ReactComponent as GavelLogo} from '../icons/gavel-logo.svg'

export default function LogoColor() {
  return (
    <Link to='/'>
      <GavelLogo className='logo-color__logo' alt='Gavel Logo - Color' />
    </Link>
  )
}
