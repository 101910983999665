import React, {useState} from 'react'
import {useAuth} from '../../contexts/AuthContext'
import {useNavigate, useLocation} from 'react-router-dom'

import FullScreenContainer from '../../components/FullScreenContainer'
import LogoColor from '../../components/LogoColor'
import StatusMessage from '../../components/StatusMessage'
import TextInput from '../../components/TextInput'
import AuthButton from '../../components/AuthButton'
import AuthLink from '../../components/AuthLink'

export default function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const {login} = useAuth()
  const navigate = useNavigate()
  const {pathname} = useLocation()

  function handleFormChange(e) {
    const {name, value} = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  function handleLogin(e) {
    e.preventDefault()
    setLoading(true)
    const {email, password} = formData
    login(email, password)
      .then(() => {
        navigate('/')
      })
      .catch((err) => {
        console.log(err.message)
        setError('Failed to login. Check your email and password.')
      })
      .finally(() => setLoading(false))
  }

  return (
    <FullScreenContainer className='login'>
      <LogoColor />
      {error && <StatusMessage type='error'>{error}</StatusMessage>}
      <form className='login__form' onSubmit={handleLogin}>
        <TextInput
          className='text-input--auth'
          type='email'
          required={true}
          label='Email Address'
          value={formData.email}
          name='email'
          handleChange={handleFormChange}
        />
        <TextInput
          className='text-input--auth'
          type='password'
          required={true}
          label='Password'
          value={formData.password}
          name='password'
          handleChange={handleFormChange}
        />
        <AuthButton label='Login' disabled={loading} />
      </form>
      <p className='login__or'>or</p>
      <AuthLink
        to='/create-account'
        state={{from: pathname}}
        label='Create Account'
      />
      <AuthLink
        className='auth-link--beige'
        to='/reset-password'
        label='Forgot Password?'
      />
    </FullScreenContainer>
  )
}
