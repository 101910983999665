import {React, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as GavelIcon} from '../icons/gavel-icon.svg'
import {ReactComponent as CloseIcon} from '../icons/close.svg'
import {ReactComponent as MenuIcon} from '../icons/menu.svg'

export default function NavBar({children}) {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 700)
  const [menuExpanded, setMenuExpanded] = useState(isLargeScreen)

  const navigate = useNavigate()

  function updateMedia() {
    setIsLargeScreen(window.innerWidth >= 700)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  }, [])

  useEffect(() => setMenuExpanded(window.innerWidth >= 700), [isLargeScreen])

  function toggleMenu() {
    setMenuExpanded(prevMenuExpanded => !prevMenuExpanded)
  }

  return (
    <nav className={'navbar' + (menuExpanded ? '' : ' navbar--hidden')}>
      <GavelIcon
        className='navbar__logo'
        alt='Gavel Icon - White'
        onClick={() => navigate('/')}
      />
      {!isLargeScreen &&
        (menuExpanded ? (
          <CloseIcon
            className='navbar__menuToggle'
            alt='Close Icon - White'
            onClick={toggleMenu}
          />
        ) : (
          <MenuIcon
            className='navbar__menuToggle'
            alt='Menu Icon - White'
            onClick={toggleMenu}
          />
        ))}
      <div className='navbar__div'>{children}</div>
    </nav>
  )
}

NavBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
}
