import React from 'react'
import PropTypes from 'prop-types'

export default function TextInput({
  className,
  type,
  required,
  readOnly,
  label,
  value,
  name,
  handleChange,
  min,
  max
}) {
  return (
    <label
      className={
        'text-input text-input__label' + (className ? ` ${className}` : '')
      }
    >
      {label}
      <input
        className={
          'text-input__input' + (readOnly ? ' text-input__input--readOnly' : '')
        }
        type={type}
        required={required}
        readOnly={readOnly}
        value={value}
        name={name}
        onChange={handleChange}
        min={min}
        max={max}
      />
    </label>
  )
}

TextInput.defaultProps = {
  type: 'string',
  required: true,
  readOnly: false
}

TextInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number
}
