import React from 'react'
import {createRoot} from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom'

import './scss/style.scss'

import {AuthContextProvider} from './contexts/AuthContext'
import ScrollToTop from './components/ScrollToTop'
import App from './App'

const root = createRoot(document.getElementById('root'))
root.render(
  <Router>
    <ScrollToTop />
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </Router>
)
