import React from 'react'
import PropTypes from 'prop-types'

export default function IconButton({className, children, buttonRef, onClick}) {
  return (
    <button
      className={'icon-button' + (className ? ` ${className}` : '')}
      ref={buttonRef}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

IconButton.propTypes = {
  className: PropTypes.string,
  buttonRef: PropTypes.object,
  onClick: PropTypes.func.isRequired
}
