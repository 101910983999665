import React from 'react'
import PropTypes from 'prop-types'

export default function SmallButton({className, text}) {
  return (
    <button className={'small-button' + (className ? ` ${className}` : '')}>
      {text}
    </button>
  )
}

SmallButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired
}
