import {useState, useRef, useEffect} from 'react'

export default function useDropdownState() {
  const [showDropdown, setShowDropdown] = useState(false)

  const dropdownRef = useRef(null)

  function toggleDropdown() {
    setShowDropdown((prevShowDropdown) => !prevShowDropdown)
  }

  useEffect(() => {
    function handleOutsideClick(e) {
      if (showDropdown && !dropdownRef.current.contains(e.target))
        setShowDropdown(false)
    }

    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('touchstart', handleOutsideClick)
    }
  }, [showDropdown])

  return [showDropdown, dropdownRef, toggleDropdown]
}
