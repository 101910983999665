import React from 'react'
import PropTypes from 'prop-types'
import {Outlet, Navigate} from 'react-router-dom'
import {useAuth} from '../contexts/AuthContext'

export default function RequireAuth({allowedRole}) {
  const {user} = useAuth()

  function returnElement() {
    if (user) {
      if (user.accountType === allowedRole) return <Outlet />
      else return <Navigate to='/' replace />
    } else {
      return <Navigate to='/login' replace />
    }
  }

  return returnElement()
}

RequireAuth.propTypes = {
  allowedRole: PropTypes.string.isRequired
}
