import React, {useState} from 'react'
import {
  collectionGroup,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  increment
} from 'firebase/firestore'
import {db} from '../../firebase'
import {useAuth} from '../../contexts/AuthContext'

import FullScreenContainer from '../../components/FullScreenContainer'
import StatusMessage from '../../components/StatusMessage'
import TextInput from '../../components/TextInput'
import AuthButton from '../../components/AuthButton'
import AuthLink from '../../components/AuthLink'

export default function CheckIn() {
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [status, setStatus] = useState('')

  const {user} = useAuth()

  function handleChange(e) {
    const {value} = e.target
    setCode(value)
  }

  function handleSubmit(e) {
    e.preventDefault()
    setLoading(true)

    // Lookup if code exists in an active event
    const eventsColRef = collectionGroup(db, 'events')
    const q = query(
      eventsColRef,
      where('code', '==', code),
      where('status', '==', 'Active')
    )
    getDocs(q)
      .then((docSnapshots) => {
        if (docSnapshots.docs.length === 0) {
          // Error message
          setError(
            'Invalid code. Check the code or check with the club admin to make sure that the event is active.'
          )
          setLoading(false)
        } else if (docSnapshots.docs[0].data().attendees[user.uid]) {
          // Already checked in
          setError('You have already been checked into this event.')
          setLoading(false)
        } else {
          const {clubId, points} = docSnapshots.docs[0].data()
          const memberDocRef = doc(db, 'members', user.uid)
          getDoc(memberDocRef)
            .then((memberDocSnapshot) => {
              const clubDocRef = doc(db, 'clubs', clubId)
              getDoc(clubDocRef)
                .then((clubDocSnapshot) => {
                  const checkInPromises = []

                  // Add member as an attendee to the events collection group
                  checkInPromises.push(
                    updateDoc(docSnapshots.docs[0].ref, {
                      [`attendees.${user.uid}`]: {
                        timestamp: serverTimestamp(),
                        name: user.displayName,
                        gradYear: memberDocSnapshot.data().gradYear
                      }
                    })
                  )

                  // If registered in club
                  if (memberDocSnapshot.data().clubs[clubId]) {
                    // Update points in members collection
                    checkInPromises.push(
                      updateDoc(memberDocSnapshot.ref, {
                        [`clubs.${clubId}.new`]: increment(points)
                      })
                    )
                  }
                  // If not registered in club
                  else {
                    // Add club to members collection
                    checkInPromises.push(
                      updateDoc(memberDocSnapshot.ref, {
                        [`clubs.${clubId}`]: {
                          name: clubDocSnapshot.data().clubName,
                          joinedAt: serverTimestamp(),
                          carryover: 0,
                          new: points
                        }
                      })
                    )
                  }

                  return Promise.all(checkInPromises)
                })
                .then(() => {
                  setStatus('Success!')
                  setLoading(false)
                })
                .catch((err) => {
                  setError('Failed to check into event.')
                  console.log(err.message)
                  setLoading(false)
                })
            })
            .catch((err) => {
              console.log(err.message)
              setLoading(false)
            })
        }
      })
      .catch((err) => {
        console.log(err.message)
        setLoading(false)
      })
  }

  return (
    <FullScreenContainer className='check-in'>
      <h1 className='check-in__title'>Enter Code</h1>
      {error && <StatusMessage type='error'>{error}</StatusMessage>}
      {status && <StatusMessage>{status}</StatusMessage>}
      <form className='check-in__form' onSubmit={handleSubmit}>
        <TextInput
          className='text-input--auth'
          type='text'
          required={true}
          label=''
          value={code}
          name='code'
          handleChange={handleChange}
        />
        {!status && (
          <AuthButton
            label={loading ? 'Submitting...' : 'Submit'}
            disabled={loading}
          />
        )}
      </form>
      {status ? (
        <AuthLink className='auth-link--darkbrown' to='/' label='Go Home' />
      ) : (
        <AuthLink className='auth-link--beige' to='/' label='Cancel' />
      )}
    </FullScreenContainer>
  )
}
