import React from 'react'

import {TailSpin} from 'react-loader-spinner'

export default function SaveLoadingSpinner() {
  return (
    <div className='save-loading-spinner'>
      <TailSpin
        height='20'
        width='20'
        color='#5e443e'
        ariaLabel='tail-spin-saving'
        radius='1'
      />
    </div>
  )
}
