import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import Card from './Card'
import LabeledText from './LabeledText'
import SmallButton from './SmallButton'

export default function EventCard({event}) {
  const date = new Date(event.date).toLocaleDateString('en-US', {
    timeZone: 'UTC'
  })

  return (
    <Card className='event-card'>
      <div>
        <h3 className='event-card__name'>{event.name}</h3>
        <p>{date}</p>
        <p>{event.status}</p>
      </div>
      <div className='event-card__grid'>
        <LabeledText text={event.code} label='Code' />
        <LabeledText text={event.points} label='Points' />
        <LabeledText
          text={event.attendees ? Object.keys(event.attendees).length : 0}
          label='Attendees'
        />
      </div>
      <div>
        <Link to={`/admin/events/${event.id}/details`}>
          <SmallButton className='small-button--margin' text='Details' />
        </Link>
        <Link to={`/admin/events/${event.id}/edit`}>
          <SmallButton text='Edit' />
        </Link>
      </div>
    </Card>
  )
}

EventCard.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    attendees: PropTypes.objectOf(PropTypes.object).isRequired
  }).isRequired
}
