import React, {useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from '../../contexts/AuthContext'

import FullScreenContainer from '../../components/FullScreenContainer'
import LogoColor from '../../components/LogoColor'
import StatusMessage from '../../components/StatusMessage'
import TextInput from '../../components/TextInput'
import AuthButton from '../../components/AuthButton'
import AuthLink from '../../components/AuthLink'

export default function CreateAccount() {
  const [formData, setFormData] = useState({
    accountType: 'Member',
    clubName: '',
    firstName: '',
    lastName: '',
    gradYear: '',
    email: '',
    password: '',
    passwordConfirmation: ''
  })
  const [loading, setLoading] = useState(false) // For modifying the button when the account is being created
  const [error, setError] = useState('')

  const {createAccount} = useAuth()
  const navigate = useNavigate()
  const {state} = useLocation()

  function handleFormChange(e) {
    const {name, value, type} = e.target
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value !== '' && type === 'number' ? parseInt(value) : value
    }))
  }

  function handleCreateAccount(e) {
    e.preventDefault()
    setLoading(true)
    const {password, passwordConfirmation} = formData

    if (password !== passwordConfirmation) {
      setError("Passwords don't match.")
      return setLoading(false)
    }

    createAccount(formData)
      .then(() => {
        setError('')
        navigate('/')
      })
      .catch(err => {
        console.log(err.message)
        setError('Failed to create acccount.')
      })
      .finally(() => setLoading(false))
  }

  return (
    <FullScreenContainer className='create-account'>
      <LogoColor />
      {error && <StatusMessage type='error'>{error}</StatusMessage>}
      <form className='create-account__form' onSubmit={handleCreateAccount}>
        <div className='create-account__accountType'>
          <p className='create-account__label'>Account Type</p>
          <div className='create-account__radioContainer'>
            <label>
              <input
                className='create-account__radio'
                type='radio'
                name='accountType'
                value='Member'
                checked={formData.accountType === 'Member'}
                onChange={handleFormChange}
              />
              Member
            </label>
            <label>
              <input
                className='create-account__radio'
                type='radio'
                name='accountType'
                value='Admin'
                checked={formData.accountType === 'Admin'}
                onChange={handleFormChange}
              />
              Admin
            </label>
          </div>
        </div>
        {formData.accountType === 'Admin' && (
          <TextInput
            className='text-input--auth'
            required={true}
            label='Club Name'
            value={formData.clubName}
            name='clubName'
            handleChange={handleFormChange}
          />
        )}
        {formData.accountType === 'Member' && (
          <TextInput
            className='text-input--auth'
            type='number'
            required={true}
            label='Graduation Year'
            value={formData.gradYear}
            name='gradYear'
            handleChange={handleFormChange}
            max={2027}
          />
        )}
        <TextInput
          className='text-input--auth'
          required={true}
          label='First Name'
          value={formData.firstName}
          name='firstName'
          handleChange={handleFormChange}
        />
        <TextInput
          className='text-input--auth'
          required={true}
          label='Last Name'
          value={formData.lastName}
          name='lastName'
          handleChange={handleFormChange}
        />
        <TextInput
          className='text-input--auth'
          type='email'
          required={true}
          label='Email Address'
          value={formData.email}
          name='email'
          handleChange={handleFormChange}
        />
        <TextInput
          className='text-input--auth'
          type='password'
          required={true}
          label='Password (At Least 6 Characters)'
          value={formData.password}
          name='password'
          handleChange={handleFormChange}
        />
        <TextInput
          className='text-input--auth'
          type='password'
          required={true}
          label='Confirm Password'
          value={formData.passwordConfirmation}
          name='passwordConfirmation'
          handleChange={handleFormChange}
        />
        <label className='create-account__legal'>
          <input
            className='create-account__checkbox'
            type='checkbox'
            name='legal'
            value='Accept'
            required
          />
          By creating an account you confirm that you are at least 13 years old,
          you accept our{' '}
          <a
            className='create-account__link'
            href='https://www.gavelapp.in/terms-of-service'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Service
          </a>
          , and you have read our{' '}
          <a
            className='create-account__link'
            href='https://www.gavelapp.in/privacy-policy'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
          .
        </label>
        <AuthButton
          label={loading ? 'Creating Account...' : 'Create Account'}
          disabled={loading}
        />
      </form>
      <AuthLink
        className='auth-link--beige'
        to={state?.from ? state.from : '/'}
        label='Cancel'
      />
    </FullScreenContainer>
  )
}
