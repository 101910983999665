import React from 'react'
import PropTypes from 'prop-types'

import useDropdownState from '../hooks/useDropdownState'

import {ReactComponent as SortIcon} from '../icons/sort.svg'

import IconButton from './IconButton'

export default function SortDropdown({sortOptions, sortBy, handleChange}) {
  const [showDropdown, dropdownRef, toggleDropdown] = useDropdownState()

  const sortButtons = sortOptions.map(sortOption => (
    <label className='sort-dropdown__radio-label' key={sortOption}>
      <input
        className='sort-dropdown__radio'
        type='radio'
        name='sortBy'
        value={sortOption}
        checked={sortBy === sortOption}
        onChange={handleChange}
      />
      {sortOption}
    </label>
  ))

  return (
    <div className='sort-dropdown' ref={dropdownRef}>
      <IconButton className='icon-button--beige' onClick={toggleDropdown}>
        <SortIcon
          className='sort-dropdown__sort-icon'
          alt='Sort Icon - Light Brown'
        />
      </IconButton>
      {showDropdown && (
        <div className='sort-dropdown__dropdown-menu'>
          <h3 className='sort-dropdown__title'>Sort By</h3>
          {sortButtons}
        </div>
      )}
    </div>
  )
}

SortDropdown.propTypes = {
  sortOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortBy: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
}
