import React from 'react'
import PropTypes from 'prop-types'

import {TailSpin} from 'react-loader-spinner'

export default function LoadingSpinner({className}) {
  return (
    <div className={'loading-spinner' + (className ? ` ${className}` : '')}>
      <TailSpin
        height='50'
        width='50'
        color='#ae9c98'
        ariaLabel='tail-spin-loading'
        radius='1'
      />
    </div>
  )
}

LoadingSpinner.propTypes = {
  className: PropTypes.string
}
