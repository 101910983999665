import React, {useState, useEffect} from 'react'
import {
  doc,
  collection,
  query,
  where,
  getDocs,
  updateDoc
} from 'firebase/firestore'
import {db} from '../../firebase'
import {useAuth} from '../../contexts/AuthContext'
import gradYearToGrade from '../../util/gradYearToGrade'

import Header from '../../components/Header'
import LoadingSpinner from '../../components/LoadingSpinner'
import SortDropdown from '../../components/SortDropdown'
import SaveLoadingSpinner from '../../components/SaveLoadingSpinner'
import SaveCloseButtons from '../../components/SaveCloseButtons'

export default function Members() {
  const [members, setMembers] = useState([])
  const [sortBy, setSortBy] = useState('Grade: Increasing')
  const [loading, setLoading] = useState(true)

  const {user} = useAuth()

  useEffect(() => {
    // Get members registered in club from members collection
    const membersColRef = collection(db, 'members')
    const q = query(membersColRef, where(`clubs.${user.clubId}`, '!=', null))
    getDocs(q)
      .then(snapshot => {
        const members = snapshot.docs
          .map(doc => ({
            id: doc.id,
            name: doc.data().name,
            gradYear: doc.data().gradYear,
            carryover: doc.data().clubs[user.clubId].carryover,
            new: doc.data().clubs[user.clubId].new,
            prevCarryover: doc.data().clubs[user.clubId].carryover,
            saving: false
          }))
          .sort(
            (a, b) => b.gradYear - a.gradYear || a.name.localeCompare(b.name)
          )
        setMembers(members)
      })
      .catch(err => console.log(err.message))
      .finally(() => setLoading(false))
  }, [])

  const sortOptions = [
    'Grade: Increasing',
    'Grade: Decreasing',
    'Name: A-Z',
    'Name: Z-A',
    'Carry-over: Increasing',
    'Carry-over: Decreasing',
    'Total Points: Increasing',
    'Total Points: Decreasing'
  ]

  function sortMembers(members, sortBy) {
    return members.toSorted((a, b) => {
      switch (sortBy) {
        case 'Grade: Decreasing':
          return a.gradYear - b.gradYear || a.name.localeCompare(b.name)
        case 'Name: A-Z':
          return a.name.localeCompare(b.name) || b.gradYear - a.gradYear
        case 'Name: Z-A':
          return b.name.localeCompare(a.name) || b.gradYear - a.gradYear
        case 'Carry-over: Increasing':
          return a.carryover - b.carryover || a.name.localeCompare(b.name)
        case 'Carry-over: Decreasing':
          return b.carryover - a.carryover || a.name.localeCompare(b.name)
        case 'Total Points: Increasing':
          return (
            a.carryover + a.new - (b.carryover + b.new) ||
            a.name.localeCompare(b.name)
          )
        case 'Total Points: Decreasing':
          return (
            b.carryover + b.new - (a.carryover + a.new) ||
            a.name.localeCompare(b.name)
          )
        default:
          return b.gradYear - a.gradYear || a.name.localeCompare(b.name)
      }
    })
  }

  function handleChange(e) {
    const newSortBy = e.target.value
    setSortBy(newSortBy)
    setMembers(prevMembers => sortMembers(prevMembers, newSortBy))
  }

  function handleCarryoverChange(e, id) {
    const {value} = e.target
    setMembers(prevMembers => {
      return prevMembers.map(prevMember => {
        if (prevMember.id === id)
          return {
            ...prevMember,
            carryover: value ? parseInt(value) : ''
          }
        else return prevMember
      })
    })
  }

  function handleSave(id) {
    // Set to saving state
    setMembers(prevMembers => {
      return prevMembers.map(prevMember => {
        if (prevMember.id === id)
          return {
            ...prevMember,
            saving: true
          }
        else return prevMember
      })
    })

    // Update carryover in members collection
    const memberDocRef = doc(db, 'members', id)
    const updatedCarryover = members.find(member => member.id === id).carryover
    updateDoc(memberDocRef, {
      [`clubs.${user.clubId}.carryover`]: updatedCarryover
    })
      .then(() =>
        // Set to saved state
        setMembers(prevMembers => {
          return prevMembers.map(prevMember => {
            if (prevMember.id === id) {
              return {
                ...prevMember,
                prevCarryover: prevMember.carryover,
                saving: false
              }
            } else return prevMember
          })
        })
      )
      .catch(err => console.log(err.message))
  }

  function handleClose(id) {
    // Reset carryover to prevCarryover
    setMembers(prevMembers => {
      return prevMembers.map(prevMember => {
        if (prevMember.id === id)
          return {
            ...prevMember,
            carryover: prevMember.prevCarryover
          }
        else return prevMember
      })
    })
  }

  function tableData() {
    if (members.length === 0)
      return (
        <tr className='members__tableRow'>
          <td>Members will show up here...</td>
        </tr>
      )
    return members.map(member => (
      <tr key={member.id} className='members__tableRow'>
        <td>{gradYearToGrade(member.gradYear)}</td>
        <td>{member.name}</td>
        <td>
          <div className='members__tableContainer'>
            <input
              className={
                'members__tableInput' +
                (member.carryover === member.prevCarryover
                  ? ''
                  : ' members__tableInput--unsaved')
              }
              type='number'
              value={member.carryover}
              onChange={e => handleCarryoverChange(e, member.id)}
              disabled={member.saving}
            />
            {member.carryover !== member.prevCarryover && !member.saving && (
              <SaveCloseButtons
                handleSave={() => handleSave(member.id)}
                handleClose={() => handleClose(member.id)}
              />
            )}
            {member.prevCarryover !== member.carryover && member.saving && (
              <SaveLoadingSpinner />
            )}
          </div>
        </td>
        <td>{member.carryover + member.new}</td>
      </tr>
    ))
  }

  return (
    <article className='members'>
      <Header title='Members' />

      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='members__flex'>
            <p className='members__note'>
              Members will automatically be registered after they check-in to
              their first club event.
            </p>
            <SortDropdown
              sortOptions={sortOptions}
              sortBy={sortBy}
              handleChange={handleChange}
            />
          </div>
          <div className='members__table'>
            <table className='members__tableContent'>
              <thead>
                <tr className='members__tableHeader'>
                  <th>Grade</th>
                  <th>Name</th>
                  <th>Carry-over</th>
                  <th>Total Points</th>
                </tr>
              </thead>
              <tbody>{tableData()}</tbody>
            </table>
          </div>
        </>
      )}
    </article>
  )
}
