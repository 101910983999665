import {React, useState, useEffect} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {doc, getDoc, updateDoc, increment} from 'firebase/firestore'
import {db} from '../../firebase'
import {useAuth} from '../../contexts/AuthContext'

import Header from '../../components/Header'
import LoadingSpinner from '../../components/LoadingSpinner'
import EventForm from '../../components/EventForm'

export default function EventEdit() {
  const [isLoading, setIsLoading] = useState(true)
  const [initEventData, setInitEventData] = useState({})
  const [formData, setFormData] = useState({})

  const {eventId} = useParams()
  const navigate = useNavigate()
  const {user} = useAuth()

  useEffect(() => {
    const docRef = doc(db, 'clubs', user.clubId, 'events', eventId)
    getDoc(docRef)
      .then((docSnapshot) => {
        const {clubId, name, date, status, points} = docSnapshot.data()
        setInitEventData({
          clubId: clubId,
          name: name,
          date: date,
          points: points
        })

        setFormData({
          name: name,
          date: date,
          status: status,
          points: points
        })

        setIsLoading(false)
      })
      .catch((err) => console.log(err.message))
  }, [])

  function handleFormChange(e) {
    const {name, value, type} = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value !== '' && type === 'number' ? parseInt(value) : value
    }))
  }

  function handleSave(e) {
    e.preventDefault()

    // Check if event details have been modified
    // const formDataKeys = Object.keys(formData)
    // const modified = formDataKeys.reduce((result, key) => {
    //   if (formData[key] !== initEventData[key]) result = true
    //   return result
    // }, false)

    // Update clubs/events subcollection
    const eventsDocRef = doc(db, 'clubs', user.clubId, 'events', eventId)
    updateDoc(eventsDocRef, formData)
      .then(() => {
        // If points was modified, update clubs and members collections
        if (formData.points !== initEventData.points) {
          getDoc(eventsDocRef)
            .then((docSnapshot) => {
              const savePromises = []
              const pointDiff = formData.points - initEventData.points
              const attendeesObj = docSnapshot.data().attendees
              const attendeesKeys = Object.keys(attendeesObj)
              attendeesKeys.forEach((key) => {
                const membersDocRef = doc(db, 'members', key)
                savePromises.push(
                  updateDoc(membersDocRef, {
                    [`clubs.${user.clubId}.new`]: increment(pointDiff)
                  })
                )
              })

              return Promise.all(savePromises)
            })
            .then(() => navigate(`/admin/events/${eventId}/details`))
            .catch((err) => console.log(err.message))
        }
      })
      .then(navigate(`/admin/events/${eventId}/details`))
      .catch((err) => console.log(err.message))
  }

  function handleCancel(e) {
    e.preventDefault()
    navigate(-1)
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <article className='event-edit'>
      <Header title={`Edit ${formData.name}`} />
      <EventForm
        formData={formData}
        handleFormChange={handleFormChange}
        handleSave={handleSave}
        handleCancel={handleCancel}
      />
    </article>
  )
}
