import React from 'react'
import PropTypes from 'prop-types'

import TextInput from './TextInput'

export default function EventForm({
  formData,
  handleFormChange,
  handleSave,
  handleCancel
}) {
  return (
    <form className='event-form' onSubmit={handleSave}>
      <div className='event-form__status'>
        <p className='event-form__label'>Active Status</p>
        <label>
          <input
            className='event-form__radio'
            type='radio'
            name='status'
            value='Active'
            checked={formData.status === 'Active'}
            onChange={handleFormChange}
          />
          Active
        </label>
        <label>
          <input
            className='event-form__radio'
            type='radio'
            name='status'
            value='Inactive'
            checked={formData.status === 'Inactive'}
            onChange={handleFormChange}
          />
          Inactive
        </label>
      </div>
      <TextInput
        label='Event Name'
        value={formData.name}
        name='name'
        handleChange={handleFormChange}
      />
      <TextInput
        type='date'
        label='Event Date'
        value={formData.date}
        name='date'
        handleChange={handleFormChange}
      />
      <TextInput
        type='number'
        label='Points'
        value={formData.points}
        name='points'
        handleChange={handleFormChange}
      />
      <button className='event-form__save' type='submit'>
        Save
      </button>
      <button
        className='event-form__cancel'
        type='button'
        onClick={handleCancel}
      >
        Cancel
      </button>
    </form>
  )
}

EventForm.propTypes = {
  formData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
  }).isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}
