import React from 'react'

import FullScreenContainer from '../../components/FullScreenContainer'
import AuthLink from '../../components/AuthLink'

export default function PageNotFound() {
  return (
    <FullScreenContainer className='page-not-found'>
      <h1 className='page-not-found__header'>Page Not Found</h1>
      <AuthLink to='/' label='Go Home' />
    </FullScreenContainer>
  )
}
