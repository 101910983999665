import React from 'react'
import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'

export default function NavButton({className, to, label}) {
  return (
    <NavLink
      to={to}
      className={({isActive}) =>
        'nav-button' +
        (className ? ` ${className}` : '') +
        (isActive ? ' nav-button--current' : '')
      }
    >
      {label}
    </NavLink>
  )
}

NavButton.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
