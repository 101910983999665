import React from 'react'
import PropTypes from 'prop-types'

export default function LabeledText({className, text, label}) {
  return (
    <div className={'labeled-text' + (className ? ` ${className}` : '')}>
      <h2 className='labeled-text__text'>{text}</h2>
      <p>{label}</p>
    </div>
  )
}

LabeledText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired
}
