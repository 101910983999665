import React from 'react'
import PropTypes from 'prop-types'

export default function Grid({children}) {
  return <div className='grid'>{children}</div>
}

Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
}
