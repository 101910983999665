import React, {useState, useEffect, useContext} from 'react'
import {
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
} from 'firebase/auth'
import {doc, setDoc, collection, addDoc} from 'firebase/firestore'
import {auth, db} from '../firebase'

import LoadingSpinner from '../components/LoadingSpinner'

const AuthContext = React.createContext()

function AuthContextProvider({children}) {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubAuth = onAuthStateChanged(auth, authUser => {
      setLoading(true)
      if (authUser) {
        setUser(prevUser => ({
          ...prevUser,
          ...authUser
        }))
      } else setUser(authUser)
      setLoading(false)
    })

    return unsubAuth
  }, [])

  // Create account, update displayName, create document, and login to new account
  function createAccount(formData) {
    let {
      accountType,
      firstName,
      lastName,
      clubName,
      email,
      password,
      gradYear
    } = formData

    function trimAndCapitalize(str) {
      if (str.length === 0) return
      str = str.trim()
      return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
    }

    // Trim whitespace and capitalize
    firstName = trimAndCapitalize(firstName)
    lastName = trimAndCapitalize(lastName)
    clubName = clubName.trim()
    email = email.trim()

    // Returns a Promise
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(auth, email, password)
        .then(cred => {
          const createAccountPromises = []

          // Update displayName
          createAccountPromises.push(
            updateProfile(cred.user, {
              displayName: `${firstName} ${lastName}`
            })
          )

          // Create document
          if (accountType === 'Member') {
            createAccountPromises.push(
              // Create member document
              setDoc(doc(db, 'members', cred.user.uid), {
                name: `${firstName} ${lastName}`,
                gradYear: gradYear,
                clubs: {}
              })
            )
            // Resolve or reject Promise
            Promise.all(createAccountPromises)
              .then(() => signOut(auth))
              .then(() => signInWithEmailAndPassword(auth, email, password))
              .then(() => resolve())
              .catch(err => reject(err))
          } else {
            // Create club document
            addDoc(collection(db, 'clubs'), {
              clubName: clubName
            }).then(docRef => {
              createAccountPromises.push(
                // Create admin document
                setDoc(doc(db, 'admins', cred.user.uid), {
                  clubId: docRef.id,
                  clubName: clubName
                })
              )
              // Resolve or reject Promise
              Promise.all(createAccountPromises)
                .then(() => signOut(auth))
                .then(() => signInWithEmailAndPassword(auth, email, password))
                .then(() => resolve())
                .catch(err => reject(err))
            })
          }
        })
        .catch(err => reject(err))
    })
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  const value = {
    user,
    setUser,
    createAccount,
    login,
    logout,
    resetPassword
  }

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <LoadingSpinner className='loading-spinner--fullScreen' />
      ) : (
        children
      )}
    </AuthContext.Provider>
  )
}

function useAuth() {
  return useContext(AuthContext)
}

export {AuthContextProvider, useAuth}
